import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validInitialPattern, validPhoneNumberPattern } from './regexp.helper';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.replace(/\D/g, '');

    if (!value) {
      return { required: true };
    }

    if (value[0] !== '0') {
      return { invalidPhoneNumber: true };
    }

    if (value.length < 2) {
      return null;
    } if (!validInitialPattern.test(value.substring(0, 3))) {
      return { invalidPhoneNumber: true };
    }

    if (value.length <= 9) {
      const isValid = validPhoneNumberPattern.test(value);
      return isValid ? null : { invalidPhoneNumber: true };
    }

    return null;
  };
}
